.sidebar {
  width: 200px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }

  .sidebar .ant-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
.pdf-container {
  width: 100%;
  height: 100%;
  position: relative;

  .react-pdf__Page {
    width: 100%;
    height: 100%;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    filter: brightness(0.7);
  }
  .react-pdf__Page__textContent {
    display: none;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
}

.pdf-iframe {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* adjust as needed */
  /* border-width: 0px; */
}

.pdf-iframe::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.free-text p {
  margin: 0px;
}
.free-text ul {
  margin: 0px;
}
.free-text ol {
  margin: 0px;
}
.free-text-mobile p {
  margin: 0px;
  font-size: 12px;
}
.last-consult-mobile-screen {
  display: flex;
  align-items: center;
}
.examination-quill-mobile .ql-container.ql-snow,
.examination-quill-mobile .ql-editor {
  min-height: 106px;
}
.additional-notes-quill-mobile .ql-container.ql-snow,
.additional-notes-quill-mobile .ql-editor {
  min-height: 106px;
}


.MuiAutocomplete-inputRoot.Mui-focused .MuiInputBase-input {
  min-width: 30px !important; /*to prevent the resizing of the auto-complete on hover & focus */
}

.MuiAutocomplete-inputRoot:hover .MuiInputBase-input {
  min-width: 30px !important; /*to prevent the resizing of the auto-complete on hover & focus */
}
