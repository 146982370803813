.ql-container {
    font-family: sans-serif;
    font-size: 16px;
}
*{
  font-family: 'Roboto', sans-serif;
}

#rx-follow-up .ql-container, #rx-follow-up .ql-editor {
/* #rx-follow-up .ql-container { */
  min-height: 168px;
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.dark .Mui-disabled .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: black !important;
}

  .dark .Mui-disabled .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color : black !important
  }

  /* Custom css for reactquill */

  #rx-follow-up, #rx-examination-findings, #rx-additional-notes{
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .ql-container{
    border-top: 1px solid lightgray !important;
    border-bottom: 0px !important;
  }

  /* quill placeholder css */
  .quill > .ql-container > .ql-editor.ql-blank::before{
    font-family: sans-serif;
    font-style: normal;
    color: rgb(179, 179, 179);
  }

  /* -------------------------- */
/* React Slick */
.slick-arrow.slick-prev::before,
.slick-arrow.slick-next::before {
  content: "" !important;
}

.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-next.slick-disabled {
  opacity: 0.5;
}

/* appointment page filters select */
.filterSelect div p{
  width: calc(100% - 20px) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-item-no-top-padding{
  padding-top: 0 !important;
}


/* for remove background color from login inputs when logging in using autocomplete */
input:-webkit-autofill {transition: background-color 5000s ease-in-out 0s;}

.MuiPickersToolbar-root{
  display: none !important;
}